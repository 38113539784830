@import './custom-framework/index.css';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  font-family: 'gilroy';
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
  width: 100%;
}

input {
  -webkit-appearance: none;
  border: none;
  background: none;
}

input,
button {
  &:focus {
    outline: unset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: none !important;
  color: inherit;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

button {
  margin: none;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-ultra-light.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-light.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-regular.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-medium.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-semi-bold.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-bold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-extra-bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-black.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../fonts/gilroy-heavy.otf');
  font-weight: 900;
  font-style: normal;
}
