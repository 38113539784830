@font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-ultra-light.otf');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-light.otf');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-regular.otf');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-medium.otf');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-semi-bold.otf');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-bold.otf');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-extra-bold.otf');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-black.otf');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'gilroy';
    src: url('./assets/fonts/gilroy-heavy.otf');
    font-weight: 900;
    font-style: normal;
  }